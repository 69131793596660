import { PURPLE, RED } from 'src/styles/colors'
import { createFetchRequest, getAutonomyStyle } from 'src/genericFunctions'
import {
	faBan,
	faCopy,
	faEdit,
	faExclamationTriangle,
	faTools,
	faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import AlertFilter from 'src/components/Filter/AlertFilter'
import AlertsModal from 'src/components/AlertsModal'
import ConfirmationModal from 'src/components/ConfirmationModal'
import CreateForklift from './ForkliftCreate'
import { DisableInfo } from './styles'
import EditForklift from './ForkliftEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForkliftTable from 'src/components/Table'
import ForkliftTag from './ForkliftTag'
import InfoModal from 'src/components/InfoModal'
import { MainContentDiv } from 'src/styles/globalStyles'
import Malfunction from 'src/components/MalfunctionModal'
import MonthYearFilter from 'src/components/Filter/MonthYearFilter'
import React from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

const formatColumns = (profile_id, onViewMalfunction, orderCallback, statusFilter, lastChecklistFilter) => {
	const columns = [
		{
			accessor: 'id',
			Header: <p>ID</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'name',
			Header: <p>Nome</p>,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'serial',
			Header: <p>Serial</p>,
			csvHeader: 'Serial',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'manufacturing_date',
			Header: (
				<p>
					Data <br />
					de
					<br />
					Fabricação
				</p>
			),
			csvHeader: 'Data de Fabricação',
			width: 'auto',
			priority: 1,
			order: true,
			filterType: 'dateYear',
			showFilter: true,
		},
		{
			accessor: 'hour_meter',
			Header: <p>Horímetro</p>,
			csvHeader: 'Horímetro',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			valueFormatter: (row, value) => `${value} H`,
			orderCallback: orderCallback,
		},
		{
			accessor: 'forklift_model',
			Header: <p>Modelo</p>,
			csvHeader: 'Modelo',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'client',
			Header: dictionary.Customer,
			csvHeader: 'Cliente',
			width: 'auto',
			priority: 1,
			order: true,
			showColumn: profile_id === 1,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'branch',
			Header: <p>Sede</p>,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'amount_of_use',
			Header: (
				<p>
					Ciclos
					<br />
					Registrados
				</p>
			),
			csvHeader: 'Ciclos Registrados',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'hours',
			Header: (
				<p>
					Tempo
					<br />
					de
					<br />
					Uso
				</p>
			),
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'autonomy',
			Header: <p>Autonomia</p>,
			csvHeader: 'Autonomia',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			getStyle: getAutonomyStyle,
			orderCallback: orderCallback,
			valueFormatter: (row, value) => {
				return parseInt(value).toFixed(2).replace(".", ',')
			}
		},
		{
			accessor: 'idleness',
			Header: <p>Ociosidade</p>,
			csvHeader: 'Ociosidade',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			valueFormatter: (row, value) => {
				return value + '%'
			},
			orderCallback: orderCallback,
		},
		{
			accessor: 'real_hourmeter',
			Header: (
				<p>
					Horímetro
					<br />
					Real
				</p>
			),
			csvHeader: 'Horímetro Real',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'status',
			Header: <p>Alertas</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value === 'damaged') {
					return (
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							onClick={() => onViewMalfunction(row)}
							style={{
								color: PURPLE,
								cursor: 'pointer',
							}}
						/>
					)
				} else if (value === 'maintenance') {
					return (
						<FontAwesomeIcon
							icon={faTools}
							onClick={() => onViewMalfunction(row)}
							style={{
								color: PURPLE,
								cursor: 'pointer',
							}}
						/>
					)
				} else {
					return <p></p>
				}
			},
			showFilter: true,
			customFilter: <AlertFilter onChange={statusFilter.callback} value={statusFilter.value} />
		},
		{
			accessor: 'disable',
			Header: 'Ativada',
			csvHeader: 'Ativada',
			width: 'auto',
			priority: 1,
			showFilter: true,
			filterType: 'yesOrNo',
			valueFormatter: (row, value) => {
				if (value) {
					return <DisableInfo color="#e63b2e">Não</DisableInfo>
				} else {
					return <DisableInfo color="#40987B">Sim</DisableInfo>
				}
			},
			orderCallback: orderCallback,
		},
		{
			accessor: 'last_checklist',
			Header: (
				<p>
					Data último <br /> Checklist
				</p>
			),
			csvHeader: 'Data último checklist',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value) {
					const date = new Date(value)
					const priorDate = new Date(new Date().setDate(date.getDate() - 30))

					if (date >= priorDate) {
						return new Date(value).toLocaleDateString('pt-BR', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
						})
					} else {
						return (
							<div
								title="O último checklist foi feito há mais de 30 dias"
								style={{
									width: 'auto',
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
								}}>
								<label style={{ marginRight: '5px', color: RED }}>
									{new Date(value).toLocaleDateString('pt-BR', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									})}
								</label>
								<FontAwesomeIcon color={RED} icon={faExclamationTriangle} />
							</div>
						)
					}
				}

				return (
					<div title="Nenhum checklist realizado para esse equipamento">
						<FontAwesomeIcon color={RED} icon={faExclamationTriangle} />
					</div>
				)
			},
			orderCallback: orderCallback,
			showFilter: true,
			customFilter: <MonthYearFilter onChange={lastChecklistFilter.callback} value={lastChecklistFilter.value} />
		},
		{
			accessor: 'mtbf',
			Header: <p>Medium Time <br /> Between <br /> Fails</p>,
			csvHeader: 'Medium Time Betwen Fails',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
		{
			accessor: 'mttr',
			Header: <p>Medium Time <br /> To <br /> Repair</p>,
			csvHeader: 'Medium Time To Repair',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
	]

	return columns
}

function Forklifts({ hideLoadingBlur, showLoadingBlur }) {
	const userInfos = getItem('session')
	const profileId = userInfos.profile_id
	const limit = 100

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})

	const [loading, setLoading] = React.useState(true)
	const [columns, setColumns] = React.useState([])
	const [list, setList] = React.useState([])
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectedRows, setSelectedRows] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])
	const [alertsModal, setAlertModal] = React.useState(null)
	const [editingForklift, setEditingForklift] = React.useState(null)
	const [view, setView] = React.useState('table')
	const [confirmationModal, setConfirmationModal] = React.useState({
		title: '',
		message: '',
		show: false,
		data: null,
	})

	const [infoModal, setInfoModal] = React.useState({
		title: '',
		message: '',
		show: false,
	})

	const [registerMalfunction, setRegisterMalfunction] = React.useState({
		show: false,
		forklift: {},
	})

	const [filters, setFilters] = React.useState({
		name: '',
		serial: '',
		order: '',
		orderField: '',
	})

	const [forkliftDuplicate, setForkliftDuplicate] = React.useState()

	const loadPageData = React.useCallback(() => {
		showLoadingBlur()
		setLoading(true)

		let opt = filters
		opt.startdate = dateFilter.initialDate.valueOf()
		opt.finaldate = dateFilter.endDate.valueOf()
		opt.page = page
		opt.limit = limit

		createFetchRequest('forklift/getAll/', 'post', opt, ({ err, result }) => {
			if (err) {
				setList([])
			} else if (!err && result) {
				let formaCols = formatColumns(
					profileId,
					onViewMalfunction,
					(orderField, order) => {
						setFilters((prev) => ({ ...prev, orderField, order }))
					},
					{ callback: filterStatus, value: filters.status },
					{ callback: filterLastChecklist, value: filters.last_checklist }
				)
				setColumns(formaCols)
				setList(result.rows)
				setPagination({
					totalPages: result.totalPages,
					total: result.total,
					page: result.page,
				})
			}
			setSelectedRows([])
			setDeselectIds([])
			setSelectedIds([])
			setLoading(false)
		})
		setTimeout(() => {
			hideLoadingBlur()
		}, 5000)
	}, [
		dateFilter.endDate,
		dateFilter.initialDate,
		filters,
		hideLoadingBlur,
		page,
		profileId,
		showLoadingBlur,
	])

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked) {
				setDeselectIds([...deselectIds, row.id])
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			} else {
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
				setSelectedRows([...selectedRows], row)
			}
		} else {
			if (checked) {
				setSelectedRows([...selectedRows, row])
				setSelectedIds([...selectedIds, row.id])
			} else {
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			}
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const filterColumn = (filter, column) => {
		let newFilters = filters
		newFilters[column] = filter
		setFilters({ ...newFilters })
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const filterStatus = (event) => {
		setFilters((prev) => ({ ...prev, status: event.target.value }))
	}

	const filterLastChecklist = (event) => {
		setFilters((prev) => ({ ...prev, last_checklist: event.target.value }))
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => {
			setPage(page - 1)
		},
		next: () => {
			setPage(page + 1)
		},
		lastPage: () => {
			setPage(pagination.totalPages)
		},
		firstPage: () => {
			setPage(1)
		},
		selectPage: (value) => {
			setPage(parseInt(value))
		},
		dateFilter,
		searchFilters: filters,
	}

	const style = {
		borderRadius: '50%',
		padding: '2px',
		width: '28px',
		height: '28px',
	}

	const onDimissMalfunction = () => {
		setRegisterMalfunction({ show: false, forklift: {} })
	}

	const onSaveMalfunction = React.useCallback(
		(context) => {
			showLoadingBlur()
			createFetchRequest('/malfunction', 'post', context, ({ err, result }) => {
				if (err) console.log(err)
				else {
					console.log(result)
				}

				setRegisterMalfunction({ show: false, forklift: {} })
				hideLoadingBlur()
			})
		},
		[hideLoadingBlur, showLoadingBlur],
	)

	const handleDeleteButtonClick = (params) => {
		let isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			var context = {
				id: params.id,
			}

			createFetchRequest(
				'forklift/delete/' + params.id,
				'delete',
				context,
				async ({ err, result }) => {
					if (err) {
						console.log(err)
					} else {
						//Reset inputs values
						loadPageData()
					}
				},
			)
		}
	}

	const handleOpenEditForklift = (params) => {
		console.log(params)
		const rowValue = params

		const startValues = {
			name: rowValue.name,
			serial: rowValue.serial,
			manufacturing_date: rowValue.manufacturing_date,
			model_id: rowValue.model_id,
			client_id: rowValue.client_id,
			hour_meter: rowValue.hour_meter,
			branch_id: rowValue.branch_id,
			id: rowValue.id,
		}

		setEditingForklift(startValues)
	}

	const onViewMalfunction = (forklift) => {
		setAlertModal({ type: 'forklift', device: forklift })
	}

	const onCreateMalfunction = (forklift) => {
		setRegisterMalfunction({ forklift, show: true })
	}

	const handleCancelEdit = () => {
		setEditingForklift(null)
	}

	const onChangeView = (value) => {
		setView(value)
	}

	const onDisableChargerConfirm = React.useCallback((row) => {
		let message = row.disable
			? 'Deseja ativar este máquina?'
			: 'Deseja desativar este máquina?'

		setConfirmationModal({
			title: 'Atenção',
			message: message,
			show: true,
			data: row,
		})
	}, [])

	const onDisableForklift = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest(
			`forklift/disable/${confirmationModal.data.id}`,
			'put',
			null,
			({ err, result }) => {
				setConfirmationModal({ title: '', message: '', show: false })
				hideLoadingBlur()
				if (err) {
					setInfoModal({
						show: true,
						message: 'Erro ao atualizar máquina.',
						title: 'ERRO!',
					})
					return
				} else {
					setInfoModal({
						show: true,
						message: 'Máquina atualizada com sucesso.',
						title: 'OK!',
					})
					loadPageData()
					return
				}
			},
		)
	}, [confirmationModal, hideLoadingBlur, loadPageData, showLoadingBlur])

	const generatePDFDocument = (newSelectedRows) => {
		const oldRatio = window.devicePixelRatio
		window.devicePixelRatio = 1
		let id = 'cobe-forklift-tags'

		const maxTagsPerPage = 16
		let tagsCounter = 0

		let tagsList = []
		const elementsArray = []

		for (let chargerId in newSelectedRows) {
			let charger = newSelectedRows[chargerId]

			tagsList.push(
				<ForkliftTag
					image={charger.client_image}
					code={charger.qrcode}
					name={charger.name}
					id={charger.id}
					key={charger.id}
				/>,
			)

			tagsCounter++
		}

		for (let i = 0; i < tagsCounter; i = i + maxTagsPerPage) {
			console.log(i, i + maxTagsPerPage)
			let myArray = [...tagsList]

			myArray = myArray.slice(i, i + maxTagsPerPage)

			elementsArray.push(
				React.createElement(
					'div',
					{
						style: {
							width: '730px',
							padding: '40px',
							display: 'flex',
							flexWrap: 'wrap',
						},
						key: 'element' + i,
					},
					myArray,
				),
			)
		}

		let controller = 0
		const pdf = new jsPDF()

		const mountPdf = () => {
			const element = elementsArray[controller]

			let parentDiv = document.createElement('div')
			parentDiv.id = 'parentNode'

			document.body.appendChild(parentDiv)
			ReactDOM.render(element, parentDiv)

			html2canvas(parentDiv, { scale: 1, allowTaint: true, useCORS: true }).then(
				(canvas) => {
					const imgData = canvas.toDataURL('image/png')

					pdf.addImage(imgData, 'PNG', 0, 0)
					document.body.removeChild(parentDiv)

					controller++
					if (controller === elementsArray.length) {
						pdf.save(id + '.pdf')
						hideLoadingBlur()
						window.devicePixelRatio = oldRatio
					} else {
						pdf.addPage()
						mountPdf()
					}
				},
			)
		}

		mountPdf()
	}

	const contructPDFDocument = () => {
		// const oldRatio = window.devicePixelRatio
		window.devicePixelRatio = 1

		if (Object.keys(selectedRows).length < 1 && !selectAll) {
			alert(dictionary.noRowsSelected)
			return
		}

		showLoadingBlur()

		let newSelectedRows = selectAll ? list : selectedRows

		window.alert(
			`Será gerado o PDF apenas dos dados em tela. com um total de ${newSelectedRows.length} linha(s) selecionada(s).`,
		)

		generatePDFDocument(newSelectedRows)
	}

	const updatePage = React.useCallback(() => {
		setEditingForklift(null)
		loadPageData()
		setView('table')
	}, [loadPageData])

	const onDuplicate = React.useCallback((item) => {
		setForkliftDuplicate(item)
	}, [])

	React.useEffect(() => {
		if (forkliftDuplicate) {
			setView('register')
		}
	}, [forkliftDuplicate])

	const actions = [
		{
			label: <FontAwesomeIcon icon={faEdit} />,
			action: handleOpenEditForklift,
			title: 'Editar',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faTrash} />,
			action: handleDeleteButtonClick,
			title: 'Excluir',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faExclamationTriangle} />,
			action: onCreateMalfunction,
			title: 'Avaria',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faBan} />,
			action: onDisableChargerConfirm,
			title: 'Desativar/Ativar',
			style: style,
			view: profileId === 1,
		},
		{
			label: <FontAwesomeIcon icon={faCopy} />,
			action: onDuplicate,
			title: 'Duplicar',
			style: style,
		},
	]

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'csv'

		createFetchRequest(
			'report/exportForkliftReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err) console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [
		dateFilter.endDate,
		dateFilter.initialDate,
		deselectIds,
		filters,
		hideLoadingBlur,
		selectAll,
		selectedIds,
		showLoadingBlur,
	])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const editModal = (
		<EditForklift
			handleCancelEdit={handleCancelEdit}
			updatePage={updatePage}
			editData={editingForklift}
		/>
	)

	const alertModal =
		alertsModal === null ? null : (
			<AlertsModal
				type={alertsModal.type}
				device={alertsModal.device}
				last={true}
				closeModal={() => setAlertModal(null)}
			/>
		)

	return (
		<MainContentDiv>
			{view === 'table' && (
				<ForkliftTable
					onGenerateCSV={onGenerateCSV}
					actions={actions}
					rows={list}
					columns={columns}
					deselectIds={deselectIds}
					selectedIds={selectedIds}
					filters={filter}
					loading={loading}
					pagination={pagination}
					selectAll={selectAll}
					registerTitle="CADASTRAR"
					onChangeView={onChangeView}
					view={view}
					name="LISTA DE MÁQUINAS"
					onSelect={onSelectRow}
					onSelectAll={onSelectAll}
					selectable={true}
					onGenerateQRCODE={contructPDFDocument}
				/>
			)}

			{view === 'register' && (
				<CreateForklift
					view={view}
					name="LISTA DE MÁQUINAS"
					onChangeView={onChangeView}
					registerTitle="CADASTRAR"
					updatePage={updatePage}
					preview={forkliftDuplicate}
				/>
			)}

			{editingForklift === null ? null : editModal}
			{alertsModal === null ? null : alertModal}
			<Malfunction
				data={registerMalfunction.forklift}
				show={registerMalfunction.show}
				onDimiss={onDimissMalfunction}
				onSuccess={onSaveMalfunction}
				title={`Registrar Avaria - Máquina ${registerMalfunction?.forklift?.name}`}
			/>
			<ConfirmationModal
				title={confirmationModal.title}
				message={confirmationModal.message}
				onClose={() => {
					setConfirmationModal({ title: '', message: '', show: false })
				}}
				onConfirmation={onDisableForklift}
				show={confirmationModal.show}
			/>
			<InfoModal
				modal={infoModal.message}
				onClose={() => {
					setInfoModal({ title: '', message: '', show: false })
				}}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(Forklifts)
